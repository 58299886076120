.headline {
  color: $white;
  text-align: center;
  padding: $spacer * 2 0 $specific-hedline-padding;

  @include media-breakpoint-up(lg) {
    padding: $spacer * 2 0 $specific-hedline-padding;
  }

  ~ * {
    margin-top: 0;
  }

  &__title {
    margin-top: 0;
    margin-bottom: $spacer / 4;
  }

  &__underTitle {
    margin-top: $spacer / 5;
    margin-bottom: $spacer /5;
    .text {
      color: $transparent-specific-color;
      -webkit-text-stroke: .02em $specific-color;
    }
  }

  &__brand{
    & img{
      height: 45px;
    }
  }

  &__subtitle {
    margin-top: $spacer-specific;
    margin-bottom: $spacer / 4;
  }
}

.headline::before {
  content: $headline-content;
  color: $primary;
  font-weight: 100;
  font-size: 40px;
}

.icon-chevron {
  @extend %subtitle-icon-chevron;
}