// Headings

@mixin h1 {
  line-height: 1.625rem * 2;
  font-weight: $headline-title-font-weight;
  margin: 3.25rem 0;
  text-transform: uppercase;
}

@mixin h2 {
  line-height: 1.625rem * 1.5;
  font-weight: $font-weight-bold;
  margin: 3.25rem 0 1.625rem;
}

@mixin h3 {
  line-height: 1.625rem * 1.2;
  font-weight: $font-weight-bold;
  margin: 1.625rem 0 0.8125rem;
}

@mixin h4 {
  line-height: 1.625rem;
  font-weight: $font-weight-bold;
  letter-spacing: .02rem;
  margin: 1.625rem 0 0.8125rem;
}

@mixin h5 {
  line-height: 1.625em;
  letter-spacing: .02rem;
  margin: 0.8125rem 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
}

h1, .h1 {
  @include h1;

  @include media-breakpoint-down(sm) {
    @include h2;
    font-size: $h2-font-size;
  }
}

h2, .h2 {
  @include h2;

  @include media-breakpoint-down(sm) {
    @include h3;
    font-size: $h3-font-size;
  }
}

h3, .h3 {
  @include h3;

  @include media-breakpoint-down(sm) {
    @include h4;
    font-size: $h4-font-size;
  }
}

h4, .h4 {
  @include h4;
}

h5, .h5 {
  @include h5;
}

h6, .h6 {
  @include h5;
  font-weight: $font-weight-bold;
}

//a, .a {
//  //font-weight: $font-weight-bold;
//  @include media-breakpoint-down(sm) {
//    font-size: 16px;
//  }
//}

p, .p {
  & a {
    color: $link-color;
  }
}

a.underline-hover{
  color: $link-color;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

button, .button {
  font-weight: $font-weight-bold;
  border-radius: $specific-border-radius !important;
}

span, .span {
  font-weight: $font-weight-normal;

  &.bold {
    font-weight: $font-weight-bold;
  }
}
