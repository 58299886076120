// Color system

$white:    #fff;
//$gray-100: #f8f9fa;
//$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
//$gray-600: #6c757d;
//$gray-700: #495057;
//$gray-800: #343a40;
//$gray-900: #212529;
$black:    #000;

$blue: #3B8CD9;
$blue-light: #69B9F8;
$darker-purple: #0B0F25CC;
$dark-purple: #0B0426;
$dark-purple-alpha: transparentize(#0B0426, 0.2);
$medium-purple: #1c1736;
$purple: #312E4E;
$light-purple: #3C3651;
$red: #ff2f19;

$yellow: #E8C32D;

$light: #FFFFFFCC;

$lighter-gray: #FFFFFFBF;
$light-gray: #FFFFFF99;
$gray: #DFE2EB;
$dark-gray: #2F323B;

$transparent: transparentize($white, .92);

//M6PLUS Colors
$m6-plus-black: #000000;
$m6-plus-dark-black: transparentize(#000000, 0.2);
$yellow_light: #FAEF00;
$specific-color: $yellow_light;
$specific-color-modal: $m6-plus-black;
$spacer-specific: 30px;
$specific-hedline-padding: 30px;
$position-specific: center 0;
$specific-footer-padding: 50px;
$specific-border-radius: 5px;
//$specific-underline: underline;

  // Body
//
// Settings for the `<body>` element.

$body-bg:                   $m6-plus-black;
$body-color:                $white;
$bg-image: '../img/M6+_Mosaique_V11.svg';

// Main Nav

$site-nav-bg: $m6-plus-dark-black;

// Footer

$footer-bg-color:                         $m6-plus-dark-black;

// Links

$link-color:                              #f4f16c;
$link-footer-color:                       $link-color;
$link-decoration:                         none;
$link-hover-color:                        $yellow_light;
$link-hover-decoration:                   none;

// Image BG

$embed-responsive-bg-color: $m6-plus-black;

// Fonts

$font-family-base: "Plus Jakarta Sans", Arial, sans-serif;
$font-family-condensed: "Plus Jakarta Sans", Arial, sans-serif;
$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * .875);

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.6;

$scale-factor: 1.414;
$h1-font-size: $font-size-base * pow($scale-factor, 3);
$h2-font-size: $font-size-base * pow($scale-factor, 2);
$h3-font-size: $font-size-base * $scale-factor;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$h1-font-size-sm: $font-size-sm * pow($scale-factor, 2);
$h2-font-size-sm: $font-size-sm * $scale-factor;
$p-font-size-sm: $font-size-sm * $scale-factor;


//$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family:        'Plus Jakarta Sans', Arial, sans-serif;
//$headings-font-weight:        500 !default;
$headings-line-height:        $line-height-base * $font-size-base;
$headings-color:              $white;
$card-overlay-color:          $black;
$card-overlay-text-position: normal;


$ssp-font-family: 'Plus Jakarta Sans', Arial, sans-serif;

$text-muted:                  $gray;

// accordion
$accordion-bg-color: $m6-plus-black;
$accordion-header-color: $yellow_light;

// Paragraph
$paragraph-margin-bottom: $line-height-base * $font-size-base;


// Spacers
$spacer: 1.625rem;

//btn
$btn-padding-y: 12px;
$btn-padding-x: 30px;
$btn-border-radius: 2px;
$primary: $yellow_light;
$primary-text-accessibility: #212529;
$primary-bg-accessibility: $primary;
$primary-bg-hover-accessibility: darken($primary, 10%);
$secondary: $purple;
$dark: $m6-plus-black;
$btn-padding-x-lg : 2rem;
$btn-padding-y-lg : 1rem;
$font-size-lg:                $font-size-base * 1.5rem !default;
$btn-center: left;


//input
$input-btn-padding-y:               .75rem;
$input-btn-padding-x:               .5rem;

$input-border-radius:               0;

$component-active-bg: #a29ec6;

$input-bg:                          $yellow_light;

$input-color:                       $black;
$input-border-width:                0;
$input-placeholder-color:          transparentize($black, 0.5);

$input-focus-bg:                    opacify($input-bg, .1);
//$input-focus-border-color:          lighten($component-active-bg, 25%) !default;
//$input-focus-color:                 $input-color !default;
//$input-focus-width:                 $input-btn-focus-width !default;
//$input-focus-box-shadow:            $input-btn-focus-box-shadow !default;

$custom-select-border-radius: 0;
$custom-select-bg:                  $yellow_light;

$dropdown-border-radius: 0;
$border-radius: 0;


// Navbar
$navbar-padding-y:                  $spacer / 1.5;
$nav-item-a-color:                  #999999;

// Breadcrumb
//$breadcrumb-padding-y:              .75rem !default;
//$breadcrumb-padding-x:              1rem !default;
//$breadcrumb-item-padding:           .5rem !default;
$breadcrumb-margin-bottom:          0;
$breadcrumb-bg:                     transparent;
//$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $white;
$breadcrumb-divider:                quote(">");
$breadcrumb-border-radius:          0;


// Navs

//$nav-tabs-border-color:             transparent;
//$nav-tabs-border-width:             0;
//$nav-tabs-border-radius:            0;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $yellow_light;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-border-width:             0.2rem;
$nav-tabs-border-color:             #666666;

// Cards

$card-border-width:                 0;
//$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    $gray-300;
$card-bg:                           $transparent;
//$card-color:                        null !default;
$card-home-font-size:               15px;
$card-title-font-size:              14px;
$card-title-fontweight:              $font-weight-bold;
$card-overlay-background:             $primary-bg-accessibility;

// Modals

// Padding applied to the modal body
$modal-inner-padding:               $spacer * 2;
//
//$modal-dialog-margin:               .5rem !default;
//$modal-dialog-margin-y-sm-up:       1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-color:               null !default;
$modal-content-bg:                  $transparent;
//$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
//$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
//$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;
//
$modal-backdrop-bg:                 $m6-plus-black;
$modal-backdrop-opacity:            .7;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1rem !default;
$modal-header-padding-x:            1rem !default;
//$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
//
//$modal-xl:                          1140px !default;
//$modal-lg:                          800px !default;
$modal-md:                          600px;
//$modal-sm:                          300px !default;
//
$modal-fade-transform:              translate(0, -20px);
//$modal-show-transform:              none !default;
//$modal-transition:                  transform .3s ease-out !default;

%card-arrow-m6 {
  display: flex;
  flex-direction: column;
}

%card-description-m6 {
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: $font-weight-bold;
}

%black-arrow-m6{
  background: $m6-plus-black;
  padding: 3px 10px 3px 20px;
  align-items: center;
  color: $specific-color;
  border-radius: 5px;
}

%subtitle-icon-m6{
  color: $specific-color;
  font-size: 20px;
  display: inline-flex;
}

%subtitle-icon-chevron{
  color: $specific-color;
  font-size: 11px;
  display: inline-flex;
}

%underline-class{
  text-decoration: underline;
}

//headline
$headline-content: "";
$transparent-specific-color: $yellow_light;
$headline-title-font-weight: $font-weight-semi-bold;

