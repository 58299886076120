.site-footer {
  background: $transparent;
  backdrop-filter: blur(20px);
  margin-top: 50px;

  &__section {
    padding: $spacer 0;

    &-dark {
      background-color: $footer-bg-color;
      padding: 50px !important;
    }

    @include media-breakpoint-up(lg) {
      padding: $spacer/2 0;
    }
  }

  &__copyright {
    align-self: center;
    text-align: center;
    font-size: $small-font-size;
    color: $gray-500;

    @include media-breakpoint-down(md) {
      margin-top: $spacer/2;
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .nav-link {
    color: $link-footer-color;
    padding: $spacer/2;
    font-size: $small-font-size;
  }

  .nav-item {
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
}



.nav--logo {

  img {
    opacity: 0.25;

    &:nth-child(2) {
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .nav-link {
    font-size: $small-font-size;

    @include media-breakpoint-up(md) {
      padding: 0 $spacer/2;
    }
  }
}



.nav--links {
  justify-content: center;

  .nav-link {
    padding: 0;
    margin: 0 $spacer/2;
  }
}
