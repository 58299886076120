.btn {
  [class*="ion-md-"] {
    margin-right: .5rem;
  }

  &-primary-accessibility{
      color: $primary-text-accessibility;
      background-color: $primary-bg-accessibility;
      border-color: $primary-bg-accessibility;
    &:hover{
      color: $primary-text-accessibility;
      background-color: $primary-bg-hover-accessibility;
      border-color: $primary-bg-hover-accessibility;
    }
  }
}

.icon {
  vertical-align: middle;
  margin-right: 2px;
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.lazy {
  opacity: 0;
}

.embed-responsive {
  background-color: transparentize($embed-responsive-bg-color, .4);
}

.embed-responsive-1245::before {
  padding-top: 36.09%;
}
