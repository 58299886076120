.page{
  //for chrome
  -webkit-font-smoothing: antialiased;
  //for firefox
  -moz-osx-font-smoothing: grayscale;
}

.site-container{
  min-height: 100vh;
}

.background-img{
  background: url($bg-image) no-repeat fixed center center;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    background-position: $position-specific;
  }
}


//to remove padding generated due to opening modal
body { padding-right: 0 !important }

.row-centered {
  justify-content: center;
}

.errorContent {
  margin: auto;

  &__title,
  &__text  {
    font-family: $headings-font-family;
    color: $light;
  }

  &__title {
    font-size: 8.2rem;
    font-weight: $font-weight-light;
    margin-bottom: 1.2rem;
  }

  &__text {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.188;
    letter-spacing: 0.1rem;
    margin-bottom: 4.1rem;
    @include media-breakpoint-up(md) {
      font-size: 3.2rem;
    }
  }

  &__link {
    max-width: 29rem;
  }

}
