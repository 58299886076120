.section {
  background: $transparent;
  backdrop-filter: blur(20px);
  margin-bottom: $spacer;
  padding: $spacer;

  @include media-breakpoint-up(md) {
    padding: $spacer * 1.5 $spacer * 2 $spacer * 2;
  }

  &__header {
    margin-bottom: $spacer;
  }

  &__title {
    margin: 0;
  }

  &__subtitle {
    text-transform: uppercase;
    margin-top: $spacer/4;
  }

  > *:first-child {
    margin-top: 0;
  }

  &--spacing{
    margin-top: 30px;
  }
}
