.site-nav {
  font-family: $ssp-font-family;
  background: $site-nav-bg;

  .navbar-brand img {
    height: 32px;
  }

  .navbar-nav {
    @include media-breakpoint-down(md) {
      padding: $spacer * 2 0;
    }
  }

  .nav-item {
    color: $white;

    @include media-breakpoint-up(lg) {
      margin: 0 $spacer/2;

      &:last-child {
        margin-right: 0;
      }
    }

    &.active {
      color: $specific-color;
      font-weight: bold;
      position: relative;
      @media (min-width : 992px) {
        &::after {
          top: 57px;
          position: absolute;
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $specific-color;
        }
      }
    }

    &:hover {
      color: $specific-color;
      position: relative;
      @media (min-width : 992px) {
        &::after {
          top: 57px;
          position: absolute;
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $specific-color;
        }
      }
    }

    .nav-link {
      color: inherit;

      @include media-breakpoint-down(md) {
        padding: $spacer 0;
      }
    }
  }
}



