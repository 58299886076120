@font-face {
  font-family: "fonticon";
  src: url('../fonts/fonticon.woff2') format('woff2'),
    url('../fonts/fonticon.woff') format('woff'),
    url('../fonts/fonticon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon:before {
    display: inline-block;
    font-family: "fonticon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-article:before { content: "\EA06" }
.icon-audiodescription:before { content: "\EA07" }
.icon-calendar:before { content: "\EA08" }
.icon-check:before { content: "\EA09" }
.icon-chevron-down:before { content: "\EA0A" }
.icon-chromecast:before { content: "\EA0B" }
.icon-cross:before { content: "\EA0C" }
.icon-csa-10:before { content: "\EA0D" }
.icon-csa-12:before { content: "\EA0E" }
.icon-csa-16:before { content: "\EA0F" }
.icon-csa-18:before { content: "\EA10" }
.icon-ear:before { content: "\EA11" }
.icon-error:before { content: "\EA12" }
.icon-eye-off:before { content: "\EA13" }
.icon-eye-on:before { content: "\EA14" }
.icon-facebook:before { content: "\EA15" }
.icon-freemium:before { content: "\EA16" }
.icon-fullscreen-off:before { content: "\EA17" }
.icon-fullscreen-on:before { content: "\EA18" }
.icon-google-podcast:before { content: "\EA19" }
.icon-home:before { content: "\EA1A" }
.icon-i-tunes:before { content: "\EA1B" }
.icon-information-default:before { content: "\EA1C" }
.icon-information-filled:before { content: "\EA1D" }
.icon-instagram:before { content: "\EA1E" }
.icon-like:before { content: "\EA1F" }
.icon-link:before { content: "\EA20" }
.icon-live:before { content: "\EA21" }
.icon-minus:before { content: "\EA22" }
.icon-pause:before { content: "\EA23" }
.icon-pinterest:before { content: "\EA24" }
.icon-play:before { content: "\EA25" }
.icon-playlist:before { content: "\EA26" }
.icon-plus:before { content: "\EA27" }
.icon-pointer-left:before { content: "\EA28" }
.icon-pointer-right:before { content: "\EA29" }
.icon-profile-connected:before { content: "\EA2A" }
.icon-profile-disconnected:before { content: "\EA2B" }
.icon-refresh:before { content: "\EA2C" }
.icon-related-video-default:before { content: "\EA2D" }
.icon-related-video-filled:before { content: "\EA2E" }
.icon-rss:before { content: "\EA2F" }
.icon-search:before { content: "\EA30" }
.icon-seekback:before { content: "\EA31" }
.icon-share:before { content: "\EA32" }
.icon-skip-next:before { content: "\EA33" }
.icon-skip-previous:before { content: "\EA34" }
.icon-sound:before { content: "\EA35" }
.icon-star:before { content: "\EA36" }
.icon-start-over:before { content: "\EA37" }
.icon-subtitles-default:before { content: "\EA38" }
.icon-subtitles-filled:before { content: "\EA39" }
.icon-tv:before { content: "\EA3A" }
.icon-twitter:before { content: "\EA3B" }
.icon-volume-down:before { content: "\EA3C" }
.icon-volume-off:before { content: "\EA3D" }
.icon-volume-up:before { content: "\EA3E" }
.icon-vost:before { content: "\EA3F" }
