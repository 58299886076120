.bypass{

  ul{
    list-style: none;
    padding: 15px 0;
    font-family: $ssp-font-family;
    background: $site-nav-bg;

    a{
      color: white;
      padding: 0 $spacer/2;

      &:hover{
        text-decoration: underline;
      }
    }
  }

}
