.card--home {
  border: 0;
  height: 100%;
  color: $white;

  .icon {
    font-size: 20px;
    display: inline-flex;
  }

  .black-arrow {
    @extend %black-arrow-m6;
  }

  .card-body {
    padding: $spacer;
    min-height: 250px;

    &__main {
      text-transform: uppercase;

      h3{
        font-size: $card-title-font-size;
      }
    }

    &__overlay {
      background: $card-overlay-background;
      display: flex;
      align-items: $card-overlay-text-position;
      position: absolute;
      opacity: 0;
      width: 100%;
      color: $card-overlay-color;
      .arrow-m6 {
        @extend %card-arrow-m6;
      }
      .description-m6{
        @extend %card-description-m6;
      }

      a:focus & {
        opacity: 1!important;
      }
    }
  }
}
