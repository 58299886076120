.modal {
    &-dialog {
        backdrop-filter: blur(20px);
    }

    &-body {
        @include media-breakpoint-down(xs) {
            padding: $spacer $spacer / 2;
        }

        #modal-success & {
            background-color: #FFFFFF;
        }
    }

    &-content {
        @-moz-document url-prefix() {
            background: adjust-color($body-bg, $saturation: -15%, $lightness: 5%);
        }
    }
}

#modal-success {
    h2{
        color: $specific-color-modal;
    }
}
