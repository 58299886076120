.write-us {
    padding: 1.625rem;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &__title {
        //margin-top: 26px;
        margin-top: 0;
    }

    &__btn{
        font-weight: bold;
    }
}

.btn-center {
    text-align: $btn-center;
}

