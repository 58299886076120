.btn-center {
    text-align: $btn-center;
}

.icon {
    @extend %subtitle-icon-m6;
}

.underline {
    @extend %underline-class;
}