.card--reduced {
    margin-bottom: $spacer / 2;
    background: transparent;
    border: 0;

    .card-body {
        padding: $spacer / 4 0;
    }

    .card-title {
        transition: color .2s ease-out;
        color: $gray-400;
        line-height: 1.4;
        text-transform: uppercase;
        margin: 0;

    }

    h4 {
        font-size: $card-title-font-size;
    }

    &:hover .card-title {
        color: $white;
    }
}