.text-lg-white {
  @include media-breakpoint-up(lg) {
    color: $white !important;
  }
}

.light-text {
  color: $light;
}

.gray-text {
  color: $gray;
}

.dark-text {
  color: $darker-purple;
}

.blue-text {
  color: $blue-light;
}

.small-title {
  font-weight: $font-weight-bold;
}

.text-invert {
  color: $white;
}

.btn-xs-block {
  @include media-breakpoint-down(xs) {
    display: block;
    width: 100%;
  }
}

.gap-1 {
  gap: 10px;
}

.gap-2 {
  gap: 20px;
}

.gap-3 {
  gap: 20px;
}
