.accordion {

  .card {
    backdrop-filter: blur(20px);
  }

  > .card .card-header {
    font-weight: $card-title-fontweight;

    &:focus{
      outline: 2px solid $card-cap-color;
      outline-offset: -2px;
    }
  }

  .card-body {
    background: $accordion-bg-color;

    @include media-breakpoint-up(md) {
      padding: $spacer;
    }
  }

  .card-header {
    border-bottom: 2px solid $transparent;

    @include media-breakpoint-up(md) {
      padding: $spacer;
    }

    @include hover {
        color: $white;
    }

    &.open {
        @include plain-hover-focus {
          color: $accordion-header-color;
          background: $accordion-bg-color;
        }
    }
  }
}
