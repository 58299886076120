.breadcrumb {

    .active {
        font-weight: $font-weight-bold;
    }

    a {
        color: $lighter-gray;
    }
}

.breadcrumb-item {
    color: $white;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: $specific-color;
}

/*

li.breadcrumb-item a{
    color: $lighter-gray !important;
    text-decoration: none !important;
    @media screen and (max-width: 500px){
        font-size: 14px !important;
    }
}
li.breadcrumb-item{
    color: $white !important;
    @media screen and (max-width: 500px){
        font-size: 14px !important;
    }
}

.breadcrumb-item+.breadcrumb-item {
    @media screen and (max-width: 500px){
        font-size: 14px !important;
        padding-left: 0.2rem !important;
    }
}


 */