.linear-nav {
  &__link {
    border-bottom-color: $gray-500;
  }

  &__item .active {
    border-bottom-color: $primary !important;
    padding-bottom: 11px;
    font-weight: $font-weight-bold;
  }

  &__item .active span {
    font-weight: $font-weight-bold;
  }

  &__item a {
    color: $nav_item-a-color;
    line-height: normal;
    border: 0;
    transition: color .2s ease-out;

    &:hover {
      color: $white;
    }
  }
}

.nav-tabs .nav-link {
  border-right-width: 0;
  border-left-width: 0;
}
